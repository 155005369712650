import React, {useEffect,useState} from "react";
import clsx from "clsx";
import {Form, Input, Typography,Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import AccessBlock from "./AccessBlock";
import InputWrap from "../../InputWrap";
import SitesAlert from "../../Alerts/sites";
import HeaderFormAddUser from "./HeaderForm";

import {useCurrentSelection} from "../../../hooks/useCurrentSelection";
import {selectSitesState, selectUserState} from "../../../store/selectors";

import classes from "../AddUser.module.scss";
import {SiteAccess} from "../../../store/branches/sites/stateTypes";
import {LoadingStatus} from "../../../store/status";
import Preloader from "../../Preloader";
import {sitesAC} from "../../../store/branches/sites/actionCreators";
import {CustomButton} from "../../Button";
import {DeleteFilled} from "@ant-design/icons";
import {ApiConfig} from "../../../services/config";

const {Title} = Typography;


interface AddUserFormProps {
    onSubmit?: (values: any) => void;
    onCancel?: () => void;
    onSaveHasAccess?: (id: number, has_access: boolean) => void;
    onSavePermission?: (id: number, permission: SiteAccess["permission"]) => void;
    locationAccess?: SiteAccess[];
    form?: any;
}

const AddUserForm: React.FC<AddUserFormProps> = ({
                                                     onSubmit,
                                                     onCancel,
                                                     form,
                                                     locationAccess = [],
                                                     onSaveHasAccess,
                                                     onSavePermission,
                                                 }) => {
    const dispatch = useDispatch();
    const {client} = useCurrentSelection();
    const {current_location: currentLocation, current_assign_user, status} = useSelector(selectSitesState);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const { userData } = useSelector(selectUserState)
    const {id}: any = useParams();
    const apiSeverUrl = ApiConfig.getServerApiUrl();
    const onRemoveAssignUser = () => {
        dispatch(sitesAC.removeAssignUser({assign_user_id: id, location_id: userData?.current_location?.id.toString(), isUpdateUserPage: true}));
    };

    const onActivationLink = () => {
        dispatch(sitesAC.sendActivationLink(+current_assign_user?.id));
    };

    const handleDisable2FA = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        dispatch(sitesAC.disableUser2FA(+current_assign_user?.id));
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        form.setFieldsValue(current_assign_user);
    }, [form, current_assign_user, dispatch]);

    return (
        <Preloader isLoaded={status === LoadingStatus.LOADING}>
            <div className={clsx("header-link", classes.addUserWrap)}>
                <SitesAlert/>
                <Form name="addUser"
                      form={form}
                      initialValues={{remember: true}}
                      onFinish={onSubmit}
                >

                    <Title level={5}> <Link to="/sites">{client?.company}/</Link> {currentLocation?.title || "Site"}
                    </Title>

                    <HeaderFormAddUser onCancel={onCancel}
                                       currentAssignUser={current_assign_user}
                    />

                    <div className={classes.addUserInputsWrap}>
                        <div className={classes.container}>
                            <div className={classes.groupInputs}>
                                <div className={classes.blockWrap}>

                                    <InputWrap title="Full name*">
                                        <Form.Item name="full_name"
                                                   rules={[{
                                                       required: true,
                                                       message: "Please input your full name",
                                                   }]}>
                                            <Input/>
                                        </Form.Item>
                                    </InputWrap>

                                    <InputWrap title="Email Address*">
                                        <Form.Item name="email"
                                                   rules={[{
                                                       type: "email",
                                                       required: true,
                                                       message: "The input is not valid E-mail!",
                                                   }]}>
                                            <Input/>
                                        </Form.Item>
                                    </InputWrap>


                                    <InputWrap title="Company*">
                                        <Form.Item name="company"
                                                   rules={[{
                                                       required: true,
                                                       message: "Please input your full company",
                                                   }]}>
                                            <Input/>
                                        </Form.Item>
                                    </InputWrap>

                                    <InputWrap title="Phone*">
                                        <Form.Item name="phone"
                                                   rules={[{
                                                       required: true,
                                                       message: "Please input your full phone",
                                                   }]}>
                                            <Input/>
                                        </Form.Item>
                                    </InputWrap>

                                    <InputWrap title="Address*">
                                        <Form.Item name="address"
                                                   rules={[{
                                                       required: true,
                                                       message: "Please input your full address",
                                                   }]}>
                                            <TextArea autoSize={{minRows: 6, maxRows: 6}}/>
                                        </Form.Item>
                                    </InputWrap>

                                    {
                                        current_assign_user?.activation_key
                                        &&
                                        <InputWrap title="Activation link">
                                            <span style={{wordBreak: "break-all", fontWeight: 700}}>
                                                {`${apiSeverUrl}register/activate/${current_assign_user.activation_key}`}
                                            </span>
                                            <CustomButton color={"client"}
                                                          height={"25px"}
                                                          width={"120px"}
                                                          padding={"1px"}
                                                          fontSize={"10px"}
                                                          margin={"10px 0"}
                                                          onClick={onActivationLink}>
                                                Send activation email
                                            </CustomButton>
                                        </InputWrap>
                                    }
                                        {current_assign_user?.registered && (
          <InputWrap title="Reset Security">

                <CustomButton
                     height={"25px"}
                     width={"120px"}
                     padding={"1px"}
                     fontSize={"10px"}
                     margin={"10px 0"}
                    color="red"
                    htmlType="button"  // Ensure this is added to prevent form submission
                    onClick={handleDisable2FA}
                >
                    Reset 2FA
                </CustomButton>
           
            </InputWrap>
    )}

                                </div>

                                <div className={classes.siteAccessWrap}>
                                    <div className={classes.titleBlock}>
                                        <span className={classes.title}>Site Access</span>
                                    </div>
                                    <div className={"d-flex"}>
                                        <div className={classes.subTitle}>
                                            <span>Site</span>
                                        </div>

                                        <div className={classes.subTitle}>
                                            <span>Has Access</span>
                                        </div>

                                        <div className={classes.subTitle}>
                                            <span className={classes.subTitle}>Permissions</span>
                                        </div>
                                    </div>

                                    {locationAccess?.map((item, index) => (
                                        <AccessBlock onSavePermission={onSavePermission}
                                                     onSaveHasAccess={onSaveHasAccess}
                                                     key={item.id + index + item.site}
                                                     locationAccess={item}
                                        />
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Form>
   

  
                {
                    current_assign_user
                    &&
                    <div className={classes.buttonDelete}>
                        <CustomButton width="120px"
                                      height="40px"
                                      padding="2px 2px"
                                      fontSize="13px"
                                      className="mar-right-10"
                                      htmlType="button"
                                      color="red"
                                      onClick={onRemoveAssignUser}
                        >
                            <DeleteFilled className="mar-right-5"/>
                            <span>DELETE USER</span>
                        </CustomButton>
                    </div>
                }
                 
                                           <Modal
                title="Confirm Reset"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to reset this users 2FA? This will allow the user to login without authentication.</p>
            </Modal>
            </div>

        </Preloader>
        
    );
};

export default AddUserForm;
