// Setup2FA.tsx
import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import UserAlert from "../Alerts/user";
import classes from "./Setup2FA.module.scss";
import {Form, Typography,Input,Modal} from "antd"
import {CustomButton} from "../Button"
import {useDispatch, useSelector} from "react-redux";
import {userAC} from "../../store/branches/user/actionCreators";
import {selectUserState} from "../../store/selectors";
import {useHistory} from "react-router-dom";
import clsx from "clsx"
const {Title} = Typography;

const Setup2FA: React.FC = () => {
    const {setup2FA} = useSelector(selectUserState);
    const [token, setToken] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSetup2FA = () => {
        dispatch(userAC.setup2FA());
    };

    const handleVerify2FA = async () => {
        dispatch(userAC.verify2FA({token: token}));
    };

    const handleDisable2FA = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        dispatch(userAC.disable2FA());
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onCancel = () => {
        history.push("/");
    };


    return (
        
        <React.Fragment>
            <UserAlert />
            <div className={clsx("d-flex", "d-flex-w")}>
                <div>
                    <Title level={2}>Manage Security</Title>
                </div>
                <div className="d-flex">


                <div>
                    <Form.Item>
                        <CustomButton width="94px"
                                      height="40px"
                                      padding="2px 2px"
                                      color="gray"
                                      fontSize="13px"
                                      htmlType="button"
                                      onClick={onCancel}
                        >
                            <span>CANCEL</span>
                        </CustomButton>
                    </Form.Item>
                </div>
            </div>
       
            </div>
    
            <div className={clsx("header-link")}>
            <div className={classes.addClientWrap}>
                        <div className={classes.container}>

                            <div className={classes.description}>
                                <span>  Two-factor authentication (2FA) provides an extra layer of security to your account by requiring
                                not only your password but also a verification code generated by a 2FA application on your mobile device.</span>
                            </div>

                            <div className={classes.groupInputs}>
                                <div className={classes.blockWrap}>
    
                                {setup2FA?.registered ? (
                            <Form.Item>
                                <div>
                                    <Title level={4}>Reset Authentication</Title>
                                </div>
                                <div className={classes.blockWrap}>
                                    <p>
                                        Click below to reset your authentication, you can use this to change the device linked to your authentication or remove
                                        authentication entirely.
                                    </p>
                                </div>
                                <CustomButton
                                    width="140px"
                                    height="40px"
                                    padding="2px 2px"
                                    fontSize="13px"
                                    htmlType="button"
                                    color="red"
                                    onClick={handleDisable2FA}
                                >
                                    <span>Reset Authentication</span>
                                </CustomButton>
                            </Form.Item>
                        ) : (
                            <React.Fragment>

                                {!setup2FA?.qrcode && (
                                    <React.Fragment>
                                    <Form.Item>
                                        <div>
                                            <Title level={4}>Step 1: Set Up Authentication</Title>
                                        </div>
                                        <div className={classes.blockWrap}>
                                            <p>
                                                Before you begin download a 2FA app to your phone such as Google Authenticator. Click below to generate a QR code and begin setting up 2FA security.
                                            </p>
                                        </div>
                                        <CustomButton
                                            width="94px"
                                            height="40px"
                                            padding="2px 2px"
                                            fontSize="13px"
                                            htmlType="button"
                                            onClick={handleSetup2FA}
                                            disabled={!!setup2FA?.qrcode}
                                        >
                                            <span>Setup 2FA</span>
                                        </CustomButton>
                                    </Form.Item>
                                    </React.Fragment>
                                )}

                                {setup2FA?.qrcode && (
                                    <React.Fragment>
                                        <div>
                                            <Title level={4}>Step 2: Scan QR Code On Your Phone</Title>
                                        </div>
                                        <QRCode value={setup2FA?.qrcode} />
                                        <p>
                                            Scan this QR code with your 2FA app (e.g., Google Authenticator, Authy).
                                            After scanning, enter the token generated by the app below.
                                        </p>

                                        <Form.Item>
                                            <Input
                                                type="text"
                                                placeholder="Enter token"
                                                value={token}
                                                onChange={(e) => setToken(e.target.value)}
                                                className="ant-input"
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <CustomButton
                                                width="94px"
                                                height="40px"
                                                padding="2px 2px"
                                                fontSize="13px"
                                                htmlType="submit"
                                                onClick={handleVerify2FA}
                                            >
                                                <span>Verify 2FA</span>
                                            </CustomButton>
                                        </Form.Item>
                                    </React.Fragment>
                                )}
                                </React.Fragment>
                                
                            )}
    
                        </div>
                    </div>
                </div>
            </div>
            </div>

                   {/* Confirmation Modal */}
                   <Modal
                title="Confirm Reset"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to reset your 2FA? This will remove your current 2FA setup and allow you to set up a new device.</p>
            </Modal>
        </React.Fragment>
    );
};

export default Setup2FA;
