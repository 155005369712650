import React, { useState } from 'react';
import clsx from "clsx";
import {Form, Input} from "antd";
import TextArea from "antd/lib/input/TextArea";
import InputWrap from "../../InputWrap";
import HeaderFormUserSetting from "./HeaderForm";
import {Link, useHistory} from "react-router-dom";


import classes from "../UserSetting.module.scss";
interface UserSettingFormProps {
    onSubmit?: (values: any) => void;
    onCancel?: () => void;
    form?: any;
    userData?: any;
    userName?: string;
}

const UserSettingForm: React.FC<UserSettingFormProps> = ({
                                                             onSubmit,
                                                             onCancel,
                                                             form,
                                                             userData,
                                                             userName,
                                                         }) => {
        const [loading, setLoading] = useState(false);
        const history = useHistory();

        const handleSubmit = async (values: any) => {
            setLoading(true);
            try {
              if (onSubmit) {
                await onSubmit(values); // Call existing onSubmit function if provided
              }
            } catch (error) {
              console.error('Error submitting form:', error);
              // Handle form submission error
            } finally {
              setLoading(false);
            }
          };


    return (
        <div className={clsx("header-link", classes.userSettingWrap)}>
            <Form name="user_setting"
                  form={form}
                  initialValues={{remember: true}}
                  onFinish={onSubmit}
            >
                <HeaderFormUserSetting onCancel={onCancel}/>

                <div className={classes.settingUserInputsWrap}>
                    <div className={classes.container}>
                        <div className={classes.groupInputs}>
                            <div className={classes.blockWrap}>

                                <InputWrap title="Full name*">
                                    <Form.Item name="userName"
                                               initialValue={userName}
                                               rules={[{
                                                   required: true,
                                                   message: "Please input your full name",
                                               }]}>
                                        <Input/>
                                    </Form.Item>
                                </InputWrap>

                                <InputWrap title="Company">
                                    <Form.Item name="company" initialValue={userData?.company}>
                                        <Input/>
                                    </Form.Item>
                                </InputWrap>

                                <InputWrap title="Phone">
                                    <Form.Item name="phone"
                                               initialValue={userData?.phone}>
                                        <Input/>
                                    </Form.Item>
                                </InputWrap>

                                <InputWrap title="Address">
                                    <Form.Item name="address" initialValue={userData?.address}>
                                        <TextArea autoSize={{minRows: 6, maxRows: 6}}/>
                                    </Form.Item>
                                </InputWrap>
                            </div>

                            <div className={classes.passwordsBlock}>
                                <InputWrap title="Old Password">
                                    <Form.Item name="old_password">
                                        <Input.Password type="password" />
                                    </Form.Item>
                                </InputWrap>

                                <InputWrap title="New Password">
                                    <Form.Item
                                        name="new_password"
                                        hasFeedback
                                    >
                                        <Input.Password type="password" />
                                    </Form.Item>
                                </InputWrap>

                                <InputWrap title="Confirm Password">
                                    <Form.Item
                                        name="confirm"
                                        dependencies={["new_password"]}
                                        hasFeedback
                                        rules={[
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("new_password") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("The two passwords that you entered do not match!"));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password type="password" />
                                    </Form.Item>
                                </InputWrap>
                            
      
                                 </div> 
                            </div>
                            <div>
   
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default UserSettingForm;
